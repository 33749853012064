"use client";

// Core
import { ReactNode } from "react";
import { SWRConfig, type SWRConfiguration } from "swr";

// Utils
import { log } from "@/client/utils/browser-logger";
import { verifyBuild } from "@/common/utils/verify-build";

const SWROptions: SWRConfiguration = {
  errorRetryInterval: 30 * 1000, // default: 5000
  errorRetryCount: 5,
  shouldRetryOnError: true, // default: true
  revalidateIfStale: false, // default: true
  // revalidateOnMount: true,
  revalidateOnFocus: false, // default: true
  revalidateOnReconnect: true, // default: true
  refreshInterval: 0, // default: 0 - turned off
  dedupingInterval: 2000, // default: 2000
  keepPreviousData: false, // default: false
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  onError: (error: Error, key: string) => {
    verifyBuild().isDevelopment &&
      log.crucial(`Error query key: ${key}.`, `Error global handler: ${JSON.stringify(error)}`);
  },
};

export const SWRProvider = ({ children }: { children: ReactNode }) => {
  return <SWRConfig value={SWROptions}>{children}</SWRConfig>;
};

export const HEADER_AUTH_KEY = "x-auth-key";

export const procedureStatuses = {
  pending: "pending",
  processing: "processing",
  success: "success",
  failure: "failed",
} as const;

export const EMPTY_STR: string = "";
export const UNDEFINED = void 0;

export const REQ_INCOMPLETE = "Request incomplete";

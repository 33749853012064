// Core
import { makeAutoObservable } from "mobx";

// Definitions
import type {
  BasketOrderPaymentCreditCardType,
  PaymentMethodPrimaryType,
} from "@/client/domains/basket/types";
import { BASKET_METHODS_PRIMARY } from "@/client/domains/basket/constants";
import { UNDEFINED } from "@/common/definitions/constants";

export type BasketPaymentStoreType = {
  activePrimaryMethod: PaymentMethodPrimaryType["paymentMethodPrimary"];
  setActivePrimaryMethod: (value: PaymentMethodPrimaryType["paymentMethodPrimary"]) => void;
  action3DSecure: BasketOrderPaymentCreditCardType["action"];
  setAction3DSecure: (value: BasketOrderPaymentCreditCardType["action"]) => void;
  payAnotherCard: boolean;
  setPayAnotherCard: (value: boolean) => void;
  resetBasketStoreUserActions: () => void;
};

const BasketPaymentStore = () =>
  makeAutoObservable<BasketPaymentStoreType>(
    {
      activePrimaryMethod: BASKET_METHODS_PRIMARY.bankTransfer,
      setActivePrimaryMethod(value) {
        this.activePrimaryMethod = value;
      },
      action3DSecure: UNDEFINED,
      setAction3DSecure(value: BasketOrderPaymentCreditCardType["action"]) {
        this.action3DSecure = value;
      },
      payAnotherCard: false,
      setPayAnotherCard(value: boolean) {
        this.payAnotherCard = value;
      },
      resetBasketStoreUserActions() {
        this.activePrimaryMethod = BASKET_METHODS_PRIMARY.bankTransfer;
        this.payAnotherCard = false;
      },
    },
    {},
    { autoBind: true },
  );

export default BasketPaymentStore;
